








































import { PropType } from 'vue'
import {
  yandexMap,
  ymapMarker,
  loadYmap
} from '@/plugins/YMapPlugin'
import Loading from '@/components/Loading/index.vue'
import { cloneDeep } from '@/utils/functions'
declare const ymaps: any

export default {
  name: 'ModalPickAddress',

  components: {
    Loading,
    yandexMap,
    ymapMarker
  },

  props: {
    title: {
      type: String as PropType<string>,
      default: 'Изменить локацию бизнеса'
    },
    location: {
      type: Object as PropType<object>,
      default: () => {}
    }
  },

  data () {
    return {
      showed: true,
      loading: false,
      coords: [55.753220, 37.622513],
      zoom: 11,
      localLocation: {
        address: '',
        geo_point: {
          longitude: 0,
          latitude: 0
        }
      },
      settings: {
        apiKey: '5e4300dc-6a38-4d6e-b29a-382b9bc41e71',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      controls: ['searchControl'],
      map: null,
      placemark: null,
      isAddressChanged: false
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  async mounted () {
    this.showed = this.value

    if (this.location?.geo_point?.longitude && this.location?.geo_point?.latitude) {
      this.coords = [this.location.geo_point.latitude, this.location.geo_point.longitude]
      this.zoom = 15
    }

    await loadYmap({
      ...this.settings,
      debug: true
    })
  },

  methods: {
    onModalHidden () {
      this.$emit('modal-hide')
    },

    ymapInitialized (map) {
      this.map = map

      try {
        if (this.location?.geo_point?.longitude && this.location?.geo_point?.latitude) {
          this.localLocation = cloneDeep(this.location)
          this.placemark = this.ymapCreatePlacemark(this.coords)
          this.map.geoObjects.add(this.placemark)
          this.placemark.properties.set('iconCaption', this.location?.address)
        }
      } catch (err) { console.warn(err) }
    },

    ymapHandleClick (event) {
      const coords = event.get('coords')

      this.localLocation.geo_point.latitude = coords[0]
      this.localLocation.geo_point.longitude = coords[1]

      if (this.placemark) {
        this.placemark.geometry.setCoordinates(coords)
      } else { // Если нет – создаем.
        this.placemark = this.ymapCreatePlacemark(coords)
        this.map.geoObjects.add(this.placemark)
        this.isAddressChanged = true
      }
      this.ymapGetAddress(coords)
      this.isAddressChanged = true
    },

    ymapCreatePlacemark (coords) {
      return new ymaps.Placemark(coords, {
        iconCaption: 'поиск...'
      }, {
        preset: 'islands#blackDotIconWithCaption'
      })
    },

    async ymapGetAddress (coords) {
      await this.placemark.properties.set('iconCaption', 'поиск...')

      const geocodeResult = await ymaps.geocode(coords)

      const firstGeoObject = geocodeResult.geoObjects.get(0)

      const addressLine = firstGeoObject.getAddressLine()

      this.localLocation.address = addressLine

      try {
        this.placemark.properties.set({
          // Формируем строку с данными об объекте.
          iconCaption: [
            // Название населенного пункта или вышестоящее административно-территориальное образование.
            firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
            // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
            firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
          ].filter(Boolean).join(', '),
          // В качестве контента балуна задаем строку с адресом объекта.
          balloonContent: addressLine
        })
      } catch (err) { console.warn(err) }
    },
    saveAddress () {
      this.$emit('save-address', this.localLocation)
    }
  }
}
