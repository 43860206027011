
























































import { PropType } from 'vue'
import { ProfileWorkTimeScheduleDay } from '@/store/modules/profiles/types'
import { DAYS_OF_WEEK } from '@/common/constants'

export default {
  name: 'Day',

  props: {
    day: {
      type: Object as PropType<ProfileWorkTimeScheduleDay>,
      default: () => {}
    },
    index: {
      type: Number as PropType<number>,
      default: 0
    }
  },

  data () {
    return {
    }
  },

  computed: {
    isWorking () {
      return !!this.day.works_from && !!this.day.works_until
    },
    isWorkingAllTime () {
      if (
        this.day?.works_from && this.day?.works_until &&
        this.timeMaskToNumber(this.day?.works_from) === 24 &&
        this.timeMaskToNumber(this.day?.works_until) === 24
      ) return true
      return false
    },
    name () {
      return DAYS_OF_WEEK[this.index]
    },
    isValid () {
      const from = this.day.works_from
      const to = this.day.works_until

      if (!from && !to) return true

      if (
        (
          (from.length === 5 && to.length === 5) &&
          (
            this.timeMaskToNumber(from) < this.timeMaskToNumber(to) ||
            this.timeMaskToNumber(from) === 24
          )
        ) ||
        (this.timeMaskToNumber(from) === this.timeMaskToNumber(to) && this.timeMaskToNumber(to) === 24)
      ) {
        return true
      }
      return false
    }
  },

  methods: {
    timeMask (value) {
      const hours = [
        /[0-2]/,
        value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/
      ]
      const minutes = [/[0-5]/, /[0-9]/]

      return value.length > 2
        ? [...hours, ':', ...minutes]
        : hours
    },

    timeMaskToNumber (val: string) {
      if (val === '00:00' || val === '00:00:00') return 24
      return +val.replace(':', '.')
    },

    toggleWeekend (flag) {
      this.$emit('toggle-weekend', { index: this.index, flag, id: this.day?.id || null })
    },

    toggleWorkingAllTime (flag) {
      this.$emit('toggle-working-all-time', { index: this.index, flag, id: this.day?.id || null })
    }
  }

}
