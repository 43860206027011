





















import { ProfileWorkTimeScheduleDay } from '@/store/modules/profiles/types'
import { PropType } from 'vue'
import Day from './Day.vue'
import { cloneDeep } from '@/utils/functions'
import { DAYS_OF_WEEK } from '@/common/constants'

export default {

  components: {
    Day
  },

  props: {
    schedule: {
      type: Array as PropType<ProfileWorkTimeScheduleDay[]>,
      default: () => []
    },
    profile: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      daysOfWeek: DAYS_OF_WEEK,
      changedSchedule: [],
      weekends: [],
      isWorkingAllTime: false
    }
  },

  created () {
    // if schedule === [] it means that is working 24\7
    if (!this.schedule.length) {
      this.isWorkingAllTime = true
    } else {
      this.changedSchedule = this.formattedSchedule(this.schedule)
    }
  },

  computed: {
    displayedDays () {
      const days = []
      for (let i = 0; i < 7; i++) {
        days.push({
          day_of_week: i
        })
      }
      this.changedSchedule.filter(item => {
        days[item.day_of_week] = item
        return item
      })

      return days
    }
  },

  watch: {
    changedSchedule: {
      handler (newVal, oldVal) {
        this.$emit('changed', this.changedSchedule)
      },
      deep: true,
      immediate: true
    },

    profile: {
      handler (value) {
        this.changedSchedule = this.changedSchedule.map(i => {
          const item = value?.profile_data?.work_time_schedule.find(oi => oi.day_of_week === i.day_of_week)
          if (!i.id) {
            i.id = item.id
          }
          return {
            ...item,
            ...i
          }
        })
      },
      deep: true
    }
  },

  methods: {
    validateTime (value) {
      let time = value
      // if time format is 00:00:00
      if (time && time.length > 5) {
        time = time.substring(0, time.length - 3)
      }
      // if time format is 0:00
      if (time.length === 4) {
        time = `0${time}`
      }
      return time
    },

    // return sorted schedule
    formattedSchedule (schedule) {
      const chunckedSchedule = cloneDeep(schedule).map(item => {
        const chunckedItem = cloneDeep(item)
        chunckedItem.works_from = this.validateTime(chunckedItem.works_from)
        chunckedItem.works_until = this.validateTime(chunckedItem.works_until)
        return { ...chunckedItem, isValid: true }
      })
      return chunckedSchedule.sort(function (a, b) {
        return parseInt(a.day_of_week) - parseInt(b.day_of_week)
      })
    },

    handleToggleChanged (flag) {
      // if true it means that it will be working 24\7
      // we need make DELETE request for existing days and delete all days in schedule array
      if (flag === true) {
        this.changedSchedule.filter(day => {
          if (!this.weekends.includes(day.id)) {
            this.weekends.push(day.id)
          }
          return null
        })
        this.changedSchedule = []
      } else {
        // else we revert changes
        this.changedSchedule = this.formattedSchedule(this.schedule)
        this.weekends = []
      }
      this.$emit('changed', this.changedSchedule)
    },

    returnSchdule () {
      return { schedule: this.changedSchedule, weekends: this.weekends }
    },

    handleToggleWeekend ({ index, flag, id }) {
      // if it changed to weekend we remove item from schedule array and push to weekends array
      if (flag) {
        if (id && !this.weekends.includes(id)) {
          this.weekends.push(id)
        }
        this.changedSchedule = this.changedSchedule.filter(item => item.day_of_week !== index)
      } else {
        const scheduleItem = this.formattedSchedule(this.schedule)
          .find(({ day_of_week: scheduleDay }) => scheduleDay === index)

        // if item was in the schedule we return it to list of objects
        if (scheduleItem) {
          this.changedSchedule.push({ ...scheduleItem })
        } else {
          this.changedSchedule.push({
            day_of_week: index,
            works_from: '09:00',
            isValid: true,
            works_until: '18:00'
          })
        }

        this.weekends.map(weekend => weekend !== id)
        this.changedSchedule = this.formattedSchedule(this.changedSchedule)
      }
      this.$emit('changed', this.changedSchedule)
    },

    isAllDaysIsWorking () {
      let allDaysIsWorking = true
      this.changedSchedule.filter((item) => {
        if (item.works_from !== '00:00' || item.works_until !== '00:00') {
          allDaysIsWorking = false
        }
        return item
      })
      this.isWorkingAllTime = this.changedSchedule.length === 7 && allDaysIsWorking
      return this.changedSchedule.length === 7 && allDaysIsWorking
    },

    handleToggleWorkingAllTime ({ index, flag, id }) {
      // if ${index} element don't exist in the changedSchedule we push it
      if (!this.changedSchedule.map(item => item.day_of_week).includes(index)) {
        this.changedSchedule.push({
          day_of_week: index,
          works_from: flag ? '00:00' : '09:00',
          isValid: true,
          works_until: flag ? '00:00' : '18:00'
        })
        this.changedSchedule = this.isAllDaysIsWorking() ? [] : this.formattedSchedule(this.changedSchedule)
        this.$emit('changed', this.changedSchedule)
        return
      }
      // else we find and change element
      this.changedSchedule = this.changedSchedule.map(item => {
        if (item.day_of_week === index) {
          let from = flag ? '00:00' : '09:00'
          let until = flag ? '00:00' : '18:00'

          const defaultItem = this.formattedSchedule(this.schedule)
            .find(({ id: scheduleId }) => scheduleId === id)

          // if dont work 24h and previosly was 24h-working day
          // in current case without it we can't set time, because it sets from default 00:00
          // and 00:00 case without inputs. it's bad.
          if (!flag && defaultItem) {
            if (defaultItem?.works_from && defaultItem?.works_from !== '00:00') {
              from = defaultItem.works_from
            }
            if (defaultItem?.works_until && defaultItem?.works_until !== '00:00') {
              until = defaultItem.works_until
            }
          }

          return {
            day_of_week: index,
            works_from: from,
            isValid: true,
            works_until: until,
            id
          }
        }
        return item
      })
      if (this.isAllDaysIsWorking()) {
        this.changedSchedule = []
      }
      this.weekends.map(weekend => weekend !== id)
      this.$emit('changed', this.changedSchedule)
    }
  }

}
